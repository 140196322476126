import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { ICarrier } from '../interfaces/carrier';

export interface ICarrierDynamicControlsParameters {
    formGroup?: string;
}

export class CarrierDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private carrier?: ICarrier, additionalParameters?: ICarrierDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Carrier';

        this.Form = {
            AccountNo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Account No',
                name: 'AccountNo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.carrier && this.carrier.hasOwnProperty('AccountNo') && this.carrier.AccountNo != null ? this.carrier.AccountNo.toString() : '',
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.carrier && this.carrier.hasOwnProperty('Archived') && this.carrier.Archived != null ? this.carrier.Archived : false,
            }),
            InvoiceForPickupFees: new DynamicField({
                formGroup: this.formGroup,
                label: 'Invoice For Pickup Fees',
                name: 'InvoiceForPickupFees',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.carrier && this.carrier.hasOwnProperty('InvoiceForPickupFees') && this.carrier.InvoiceForPickupFees != null ? this.carrier.InvoiceForPickupFees : false,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.carrier && this.carrier.hasOwnProperty('Name') && this.carrier.Name != null ? this.carrier.Name.toString() : '',
            }),
        };

        this.View = {
            AccountNo: new DynamicLabel({
                label: 'Account No',
                value: this.carrier && this.carrier.hasOwnProperty('AccountNo') && this.carrier.AccountNo != null ? this.carrier.AccountNo.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.carrier && this.carrier.hasOwnProperty('Archived') && this.carrier.Archived != null ? this.carrier.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            InvoiceForPickupFees: new DynamicLabel({
                label: 'Invoice For Pickup Fees',
                value: this.carrier && this.carrier.hasOwnProperty('InvoiceForPickupFees') && this.carrier.InvoiceForPickupFees != null ? this.carrier.InvoiceForPickupFees : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.carrier && this.carrier.hasOwnProperty('Name') && this.carrier.Name != null ? this.carrier.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
