import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, ISelectionChangedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { OrderService } from '../../order.service';
import { WarehouseService } from '../../../warehouses/services/warehouse.service';
import { IOrder } from '../../../model/interfaces/order';
import { entityListModuleConfig } from '../../../common/shared.module';
import { OrdersEntityListConfig } from './order-selection.entity-list-config';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { CommonFunctions } from '../../../common/services/common-functions.service';
import { OfficeService } from '../../../offices/services/office.service';
import { IWarehouse } from '../../../model/interfaces/warehouse';
import { IOffice } from '../../../model/interfaces/office';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ActivatedRoute, Router } from '@angular/router';
import { IPickup } from '../../../model/interfaces/pickup';
import { PickupService } from '../../../model/shared-entities/pickups/pickup.service';
import { AuthService } from '@mt-ng2/auth-module';
import { UserRoles } from '../../../model/UserRoles';
import { CustomerService } from '../../../customers/customer.service';
import { ICustomer } from '../../../model/interfaces/customer';
import { OrderLimits } from '../../../model/classes/order-limits';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';

@Component({
    selector: 'app-pickup-scheduling-order-selection',
    templateUrl: './order-selection.component.html',
})
export class PickupSchedulingOrderSelection implements OnInit {
    orders: IOrder[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    total: number;
    query = '';
    selectedWarehouseIds: number[] = [];
    selectedOfficeIds: number[] = [];
    selectedCustomerIds: number[] = [];    
    entityListConfig: OrdersEntityListConfig;
    order = 'OrderNumber';
    orderDirection: string;
    startDate: Date;
    endDate: Date;
    datepickerEntityName = 'Date Created';
    warehouses: IWarehouse[];
    offices: IOffice[];
    customers: ICustomer[];
    authUserRole: number;
    selectedOrder: IOrder;
    selectedOrders: IOrder[];
    pickupIdParam = 0;
    selectedPickup: IPickup;
    subscriptions: Subscription = new Subscription();
    orderLimits: OrderLimits;
    orderTotalPalletDialogApi: IModalWrapperApi;
    orderTotalWeightDialogApi: IModalWrapperApi;
    toasterOptions = { timeOut: 5000, positionClass: 'toast-center-center' };

    constructor(
        private orderService: OrderService,
        private pickupService: PickupService,
        private warehouseService: WarehouseService,
        private officeService: OfficeService,
        private customerService: CustomerService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.pickupIdParam = Number(params['id']);
                this.pickupService.getById(this.pickupIdParam).subscribe((answer) => { 
                    this.pickupService.selectedPickup$.next(answer);
                });
            } else {
                this.pickupService.selectedPickup$.next(null);
            }

            const currentUser = this.authService.currentUser.getValue();
            forkJoin([
                this.warehouseService.getActive(), 
                this.officeService.getActive(), 
                this.customerService.getActiveCustomers(), 
                this.orderService.getAuthUserRole(currentUser.AuthId),
                this.pickupService.getOrderLimits(),
            ])
            .subscribe(([warehouses, offices, customers, authUserRole, orderLimits]) => {
                this.warehouses = warehouses;
                this.offices = offices;
                this.customers = customers;
                this.authUserRole = authUserRole;
                this.orderLimits = orderLimits;
                this.entityListConfig = new OrdersEntityListConfig(this.authUserRole, this.orderService, this.orderTotalPalletDialogApi, this.orderTotalWeightDialogApi);
                if (this.pickupIdParam > 0) {
                    this.order = 'PickupId';
                    this.orderDirection = 'desc';
                } else {
                    this.orderDirection = this.entityListConfig.getDefaultSortDirection();
                }
                
                this.getOrdersCall().subscribe((answer) => {
                    this.orders = answer.body;
                    this.total = +answer.headers.get('X-List-Count');
                    this.syncSelectedOrders();
                    this.buildSearchBarAndFilters();
                });
            });
        });

        this.orderService.selectedOrder$.subscribe((order) => {
            this.selectedOrder = order;
        });
    }

    search(evt: DynamicSearchValues): void {
        this.currentPage = 1;
        this.query = evt.Searchbar as string;
        if (evt.Warehouses) {
            this.selectedWarehouseIds = (evt.Warehouses as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Offices) {
            this.selectedOfficeIds = (evt.Offices as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Customers) {
            this.selectedCustomerIds = (evt.Customers as IMetaItem[]).map(x => x.Id);
        }
        this.getOrders();
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'WarehouseIds',
                valueArray: this.selectedWarehouseIds,
            }),
            new ExtraSearchParams({
                name: 'OfficeIds',
                valueArray: this.selectedOfficeIds,
            }),
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: this.selectedCustomerIds,
            }),
        );

        if (this.startDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateCreatedStartDate',
                value: this.startDate.toDateString(),
            }));
        }

        if (this.endDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateCreatedEndDate',
                value: this.endDate.toDateString(),
            }));
        }

        return _extraSearchParams;
    }

    getOrdersCall(): Observable<HttpResponse<IOrder[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.orderService.pickupOrdersSearch(searchparams, this.pickupIdParam);
    }

    getOrders(): void {
        this.getOrdersCall().subscribe((answer) => {
            this.orders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.selectedOrders?.forEach(element => {
                if (!this.orders.find(o => o.Id === element.Id))
                    this.orders.unshift(element);    
            });
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getOrders();
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.getOrders();
    }

    buildSearchBarAndFilters(): void {
        this.dynamicSearchFiltersConfig = [{
            Searchbar: {
                label: 'Search',
                type: DynamicSearchFilterTypes.Searchbar,
            },
            Warehouses: {
                label: 'Warehouse',
                type: DynamicSearchFilterTypes.Select,
                options: {
                    
                    selectOptions: CommonFunctions.mapMtSearchFilterItems(this.warehouses, 'WarehouseId')
                },
            },
            Offices: {
                label: 'Office',
                type: DynamicSearchFilterTypes.Select,
                options: {
                    selectOptions: CommonFunctions.mapMtSearchFilterItems(this.offices, 'Title')
                },
            },
            ...(this.authUserRole === UserRoles.Carrier || this.authUserRole === UserRoles.Admin ? {
                Customers: {
                    label: 'Customer',
                    type: DynamicSearchFilterTypes.Select,
                    options: {
                        selectOptions: CommonFunctions.mapMtSearchFilterItems(this.customers)
                    },
                }
            } : {})
        }];
    }

    clear(): void {
        this.orders = null;
        this.total = null;
        this.startDate = null;
        this.endDate = null;
        this.datepickerEntityName = null;
        this.query = '';
        this.dynamicSearchFiltersConfig = [];
        this.selectedOrders = [];
        this.selectedWarehouseIds = [];
        this.selectedOfficeIds = [];
        this.selectedCustomerIds = [];
        setTimeout(() => {
            this.datepickerEntityName = 'Date Created';
            this.buildSearchBarAndFilters();
            this.getOrders();
        });
    }
    
    selectionChanged(event: ISelectionChangedEvent): void {
        this.selectedOrders = event.selectedEntities;
    }

    nextStep() {
        if (this.hasMoreThanOneOffice(this.selectedOrders)) 
            return this.notificationsService.error('There cannot be more than one office when scheduling a pickup.');

        const hasNoWarehouse = this.hasNoWarehouse(this.selectedOrders);
        if (hasNoWarehouse) 
            return this.notificationsService.error(`Order ${hasNoWarehouse.OrderNumber} has no assigned warehouse, reach out to Holt to handle this issue.`);

        const hasNoOffice = this.hasNoOffice(this.selectedOrders);
        if (hasNoOffice) 
            return this.notificationsService.error(`Order ${hasNoOffice.OrderNumber} has no assigned office, reach out to Holt to handle this issue.`);

        const hasNoCustomer = this.hasNoCustomer(this.selectedOrders);
        if (hasNoCustomer) 
            return this.notificationsService.error(`Order ${hasNoCustomer.OrderNumber} has no assigned customer, reach out to Holt to handle this issue.`);

        const orderCustomers = this.selectedOrders.map(o => o.Customer);
        const largestCustomerPalletCount = CommonFunctions.getLargestCustomerPalletCount(orderCustomers);
        const ordersTotalPallet = CommonFunctions.getOrdersTotalPallet(this.selectedOrders);
        const palletCountLimit = largestCustomerPalletCount ?? this.orderLimits.PalletCount;
        
        if (ordersTotalPallet > palletCountLimit) 
            return this.notificationsService.error(`The total Pallet Count ${ordersTotalPallet} exceeds the limit set at ${palletCountLimit}. Please try again with a lower limit.`, null, this.toasterOptions);

        const largestCustomerOrderWeight = CommonFunctions.getLargestCustomerOrderWeight(orderCustomers);
        const ordersTotalWeight = CommonFunctions.getOrdersTotalWeight(this.selectedOrders);
        const orderWeightLimit = largestCustomerOrderWeight ?? this.orderLimits.OrderWeight;
        if (ordersTotalWeight > orderWeightLimit) 
            return this.notificationsService.error(`The total Order Weight ${ordersTotalWeight} lbs exceeds the limit set at ${orderWeightLimit} lbs. Please try again with a lower limit.`, null, this.toasterOptions);

        this.orderService.selectedPickupOrders$.next(this.selectedOrders);

        if (this.authUserRole === UserRoles.Admin) {
            void this.router.navigate(['create-requests/time-selection']);
            return;
        }

        if (this.authUserRole === UserRoles.Carrier) {
            void this.router.navigate(['create-requests/driver-selection']);
            return;
        }

        void this.router.navigate(['create-requests/carrier-selection']);
    }

    pageTitle() {
        if (!this.authUserRole)
            return '';
            
        return (this.authUserRole === UserRoles.Carrier || this.authUserRole === UserRoles.Admin)
            ? 'Pickup Scheduling - Step 1 of 2'
            : 'Pickup Scheduling - Step 1 of 3';
    }

    closeOrderTotalPalletDialog(): void {
        this.orderTotalPalletDialogApi.close();
        this.getOrdersCall().subscribe((answer) => {
            this.orders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.updateSelectedOrder();
            this.selectedOrder = null;
        });
    }

    closeOrderTotalWeightDialog(): void {
        this.orderTotalWeightDialogApi.close();
        this.getOrdersCall().subscribe((answer) => {
            this.orders = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.updateSelectedOrder();
            this.selectedOrder = null;
        });
    }

    private hasMoreThanOneOffice(orders: IOrder[]): boolean {
        const officeSet = new Set();
        orders.forEach((order) => {
            if (order.Warehouse?.OfficeId ?? 0) officeSet.add(order.Warehouse?.OfficeId ?? 0);
        });
        return officeSet.size > 1 ? true : false;
    }

    private hasNoWarehouse(orders: IOrder[]): IOrder | null {
        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            if (order.WarehouseId === null || order.WarehouseId <= 0)
                return order;
        }
        return null;
    }

    private hasNoOffice(orders: IOrder[]): IOrder | null {
        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            if (order.Warehouse?.OfficeId === null || order.Warehouse?.OfficeId <= 0)
                return order;
        }
        return null;
    }

    private hasNoCustomer(orders: IOrder[]): IOrder | null {
        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            if (order.CustomerId === null || order.CustomerId <= 0)
                return order;
        }
        return null;
    }

    private syncSelectedOrders(): void {
        const pickupOrders = this.orders?.filter(o => o.PickUpId === this.pickupIdParam);
        if (pickupOrders?.length > 0) {
            this.selectedOrders = pickupOrders;
        }
    }

    private updateSelectedOrder(): void {
        if (!this.selectedOrders)
            return;

        const updatedOrder = this.orders?.find(o => o.Id === this.selectedOrder?.Id);
        if (updatedOrder?.Id > 0) {
            this.selectedOrders = this.selectedOrders.map(o => o.Id === updatedOrder.Id ? updatedOrder : o);
        }
    }
}
