import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IWarehouse } from '../../model/interfaces/warehouse';
import { IOfficeDoor } from '../../model/interfaces/office-door';

export const emptyWarehouse: IWarehouse = {
    Archived: false,
    Division: null,
    Id: 0,
    OfficeId: null,
    WarehouseId: null,
};

@Injectable()
export class WarehouseService extends BaseService<IWarehouse> {
    constructor(public http: HttpClient) {
        super('/warehouses', http);
    }

    getEmptyWarehouse(): IWarehouse {
        return { ...emptyWarehouse };
    }

    getOfficeWarehouses(officeId: number): Observable<IWarehouse[]> {
        return this.http.get<IWarehouse[]>(`/warehouses/office/${officeId}`);
    }

    getActive(): Observable<IWarehouse[]> {
        return this.http.get<IWarehouse[]>(`/warehouses/all/active`);
    }

    getUserWarehouses(): Observable<IWarehouse[]> {
        return this.http.get<IWarehouse[]>(`/warehouses/user`);
    }

    private _warehouseSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public get warehouseSaved$(): BehaviorSubject<boolean> {
        return this._warehouseSaved;
    }
}
