import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ILocation } from '../interfaces/location';
import { IAddress } from '../interfaces/address';

export interface ILocationDynamicControlsParameters {
    formGroup?: string;
    addresses?: IAddress[];
}

export class LocationDynamicControls {

    formGroup: string;
    addresses?: IAddress[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private location?: ILocation, additionalParameters?: ILocationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Location';
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;

        this.Form = {
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.AddressId || null,
            }),
            EntranceLatitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Entrance Latitude',
                name: 'EntranceLatitude',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.EntranceLatitude || null,
            }),
            EntranceLongitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Entrance Longitude',
                name: 'EntranceLongitude',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.EntranceLongitude || null,
            }),
            ExitLatitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exit Latitude',
                name: 'ExitLatitude',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.ExitLatitude || null,
            }),
            ExitLongitude: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exit Longitude',
                name: 'ExitLongitude',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [  ],
                validators: {  },
                value: this.location && this.location.ExitLongitude || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.location && this.location.hasOwnProperty('Name') && this.location.Name != null ? this.location.Name.toString() : '',
            }),
        };

        this.View = {
            AddressId: new DynamicLabel({
                label: 'Address',
                value: getMetaItemValue(this.addresses as unknown as IMetaItem[], this.location && this.location.hasOwnProperty('AddressId') ? this.location.AddressId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            EntranceLatitude: new DynamicLabel({
                label: 'Entrance Latitude',
                value: this.location && this.location.EntranceLatitude || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            EntranceLongitude: new DynamicLabel({
                label: 'Entrance Longitude',
                value: this.location && this.location.EntranceLongitude || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            ExitLatitude: new DynamicLabel({
                label: 'Exit Latitude',
                value: this.location && this.location.ExitLatitude || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            ExitLongitude: new DynamicLabel({
                label: 'Exit Longitude',
                value: this.location && this.location.ExitLongitude || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.location && this.location.hasOwnProperty('Name') && this.location.Name != null ? this.location.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
