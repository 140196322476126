import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { NgForm } from '../../../../node_modules/@angular/forms';
import { IOffice } from '../../model/interfaces/office';
import { OfficeService } from '../services/office.service';
import { IOfficeDoor } from '../../model/interfaces/office-door';
import { IWarehouse } from '../../model/interfaces/warehouse';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WarehouseService } from '../../warehouses/services/warehouse.service';

@Component({
    selector: 'app-office-doors',
    styles: [
        `
            .validator-msg {
                color: red;
            }
        `,
    ],
    templateUrl: './office-doors.component.html',
})
export class OfficeDoorsComponent implements OnInit {
    subscriptions: Subscription = new Subscription();
    @Input() office: IOffice;
    @Input() canEdit: boolean;
    doubleClickIsDisabled = false;
    selectedDoor: IOfficeDoor;
    officeDoors: IOfficeDoor[] = [];
    warehouses: IWarehouse[] = [];
    emptyDoor: IOfficeDoor = {
        Archived: false,
        Door: null,
        Id: 0,
        OfficeId: 0,
    };
    warehouseControl = new FormControl('', Validators.required);

    constructor(
        private notificationsService: NotificationsService, 
        private router: Router, 
        private officeService: OfficeService,
        private warehouseService: WarehouseService,
    ) { }

    ngOnInit(): void {
        this.refreshDoors();
        this.warehouses = this.office.Warehouses.filter(w => !w.Archived);
        
        this.subscriptions.add(
            this.warehouseService.warehouseSaved$.subscribe((saved: boolean) => {
                if (saved) {
                    this.refreshWarehouses();
                }
            })
        );
    }

    isEditMode(door: IOfficeDoor): boolean {
        return this.selectedDoor && this.selectedDoor.Id === door.Id;
    }

    setEdit(door: IOfficeDoor): void {
        this.selectedDoor = { ...door };
    }

    cancel(door: IOfficeDoor): void {
        if (door.Id === 0) {
            this.officeDoors.splice(this.officeDoors.length - 1, 1);
        }
        this.selectedDoor = null;
    }

    add(): void {
        if (!this.selectedDoor) {
            this.selectedDoor = { ...this.emptyDoor };
            this.officeDoors.push(this.selectedDoor);
        }
    }

    onFormSubmit(form: NgForm): void {
        if (form.invalid) {
            if (!this.selectedDoor.Door) {
                this.notificationsService.error('Door name is required');
            }
            return;
        }
        
        this.selectedDoor.OfficeId = this.office.Id;
        this.officeService
            .saveOfficeDoor(this.office.Id, this.selectedDoor).subscribe(
                () => {
                    this.success();
                },
                () => {
                    this.error();
                },
            );
    }

    onDelete(event: IOfficeDoor): void {
        this.officeService.deleteOfficeDoor(event.Id)
            .subscribe(
                () => {
                    this.success();
                },
                () => {
                    this.error();
                },
            );
    }

    refreshDoors(): void {
        this.officeService.getOfficeDoorsByOffice(this.office.Id)
            .subscribe(
                (answer) => {
                    this.officeDoors = answer;
                },
                () => {
                    this.error();
                },
            );
    }

    refreshWarehouses(): void {
        this.officeService.getById(this.office.Id).subscribe({
            next: (office => {
                this.office = office;
                this.warehouses = office.Warehouses.filter(w => !w.Archived);
            })
        });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.selectedDoor = null;
        this.refreshDoors();
        this.notificationsService.success('Saved Successfully');
    }
}
