import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, IEntity, SortDirection } from '@mt-ng2/entity-list-module';
import { WarehouseService } from '../../../warehouses/services/warehouse.service';
import { entityListModuleConfig } from '../../../common/shared.module';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { CommonFunctions } from '../../../common/services/common-functions.service';
import { OfficeService } from '../../../offices/services/office.service';
import { IWarehouse } from '../../../model/interfaces/warehouse';
import { IOffice } from '../../../model/interfaces/office';
import { PickupService } from '../../../model/shared-entities/pickups/pickup.service';
import { IPickupForSearch } from '../../../model/interfaces/custom/pickup-for-search';
import { CarrierService } from '../../../carriers/services/carrier.service';
import { ICarrier } from '../../../model/interfaces/carrier';
import { PickupStatus } from '../../order-basic-info/pickup-status-enum';
import { OrderService } from '../../order.service';
import { AuthService } from '@mt-ng2/auth-module';
import { CustomerService } from '../../../customers/customer.service';
import { ICustomer } from '../../../model/interfaces/customer';
import { UserRoles } from '../../../model/UserRoles';
import { IUser } from '../../../model/interfaces/user';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { DatePipe } from '@angular/common';
import { IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { SweetAlertResult } from 'sweetalert2';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Router } from '@angular/router';
import { IOrder } from '../../../model/interfaces/order';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';
import { ISelectionChangedEvent } from '@mt-ng2/type-ahead-control';

@Component({
    selector: 'app-pickup-scheduling-manage-pickups',
    templateUrl: './manage-pickups.component.html',
    styleUrls: ['./manage-pickups.component.css'],
})
export class PickupSchedulingManagePickupsComponent implements OnInit {
    pickups: IPickupForSearch[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    total: number;
    query = '';
    selectedWarehouseIds: number[] = [];
    selectedOfficeIds: number[] = [];
    selectedCarrierIds: number[] = [];
    selectedStatusIds: number[] = [PickupStatus.Requested, PickupStatus.Confirmed];
    selectedCustomerIds: number[] = [];
    selectedDriverIds: number[] = [];
    authUserRole: number;
    order = 'Id';
    orderDirection = 'asc';
    startDate: Date;
    endDate: Date;
    datepickerEntityName = 'Date Requested';
    warehouses: IWarehouse[];
    offices: IOffice[];
    carriers: ICarrier[];
    customers: ICustomer[];
    drivers: IUser[];
    selectedPickup: IPickupForSearch;
    subscriptions: Subscription = new Subscription();
    isCarrierUser = false;
    isCustomerUser = false;
    isAdminUser = false;
    selectedOrder: IOrder;
    orderTotalPalletDialogApi: IModalWrapperApi;
    orderTotalWeightDialogApi: IModalWrapperApi;
    showPrePayButtons = new Map<number, boolean>();
    maxToShow = 1000;

    constructor(
        private pickupService: PickupService,
        private warehouseService: WarehouseService,
        private officeService: OfficeService,
        private carrierService: CarrierService,
        private orderService: OrderService,
        private customerService: CustomerService,
        private authService: AuthService,
        private viewContainerRef: ViewContainerRef,
        private datePipe: DatePipe,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        const currentUser = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(currentUser.AuthId).subscribe(authUserRole => {
            this.authUserRole = authUserRole;
            this.isCarrierUser = this.authUserRole === UserRoles.Carrier;
            this.isCustomerUser = this.authUserRole === UserRoles.Customer;
            this.isAdminUser = this.authUserRole === UserRoles.Admin;
            if (this.authUserRole === UserRoles.Carrier || this.authUserRole === UserRoles.Admin) {
                this.pickupService.getPickupDrivers().subscribe(drivers => {
                    this.drivers = drivers;
                    this.getManagePickupsTableData();
                });
            } else {
                this.getManagePickupsTableData();
            }
        });
    }

    getManagePickupsTableData(): void {
        forkJoin([
            this.warehouseService.getUserWarehouses(),
            this.officeService.getActive(),
            this.carrierService.getActive(),
            this.customerService.getActiveCustomers(),
        ]).subscribe(([warehouses, offices, carriers, customers]) => {
            this.warehouses = warehouses;
            this.offices = offices;
            this.carriers = carriers;
            this.customers = customers;
            this.orderService.authUserRole$.next(this.authUserRole);
            this.buildSearchBarAndFilters();
            this.getPickups();
        });
    }

    search(evt: DynamicSearchValues): void {
        const expandedPickup = document.getElementById(`expanded-pickup-${this.selectedPickup?.Id}`);
        if (expandedPickup) {
            expandedPickup.remove();
        }
        this.currentPage = 1;
        this.query = evt.Searchbar as string;
        if (evt.Warehouses) {
            this.selectedWarehouseIds = (evt.Warehouses as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Offices) {
            this.selectedOfficeIds = (evt.Offices as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Carriers) {
            this.selectedCarrierIds = (evt.Carriers as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Customers) {
            this.selectedCustomerIds = (evt.Customers as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Drivers) {
            this.selectedDriverIds = (evt.Drivers as IMetaItem[]).map(x => x.Id);
        }
        if (evt.Statuses) {
            this.selectedStatusIds = (evt.Statuses as IMetaItem[]).map(x => x.Id);
        }
        this.getPickups();
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        let selectedStatusIds = this.selectedStatusIds;
        if (this.selectedStatusIds.length === 0) {
            selectedStatusIds = [
                PickupStatus.Requested,
                PickupStatus.Confirmed,
            ];
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'WarehouseIds',
                valueArray: this.selectedWarehouseIds,
            }),
            new ExtraSearchParams({
                name: 'OfficeIds',
                valueArray: this.selectedOfficeIds,
            }),
            new ExtraSearchParams({
                name: 'CarrierIds',
                valueArray: this.selectedCarrierIds,
            }),
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: this.selectedCustomerIds,
            }),
            new ExtraSearchParams({
                name: 'DriverIds',
                valueArray: this.selectedDriverIds,
            }),
            new ExtraSearchParams({
                name: 'StatusIds',
                valueArray: selectedStatusIds,
            }),
        );

        if (this.startDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateRequestedStartDate',
                value: this.startDate.toDateString(),
            }));
        }

        if (this.endDate) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'DateRequestedEndDate',
                value: this.endDate.toDateString(),
            }));
        }

        return _extraSearchParams;
    }

    getPickupsCall(): Observable<HttpResponse<IPickupForSearch[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
        };

        const searchparams = new SearchParams(searchEntity);
        return this.pickupService.search(searchparams);
    }

    getPickups(): void {
        this.getPickupsCall().subscribe((answer) => {
            this.pickups = answer.body;
            this.total = +answer.headers.get('X-List-Count');
            this.initShowPrePayButtons(this.pickups);
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getPickups();
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.getPickups();
    }

    buildSearchBarAndFilters(): void {
        const pickupStatuses = Object.keys(PickupStatus)
            .filter(key => Number(key) === PickupStatus.Requested || Number(key) === PickupStatus.Confirmed)
            .map(key => ({
                Id: Number(key),
                Name: PickupStatus[Number(key)]
            }));

        this.dynamicSearchFiltersConfig = [{
            Searchbar: {
                label: 'Search',
                type: DynamicSearchFilterTypes.Searchbar,
            },
            Warehouses: {
                label: 'Warehouse',
                type: DynamicSearchFilterTypes.Select,
                options: {
                    selectOptions: CommonFunctions.mapMtSearchFilterItems(this.warehouses, 'WarehouseId')
                },
            },
            Offices: {
                label: 'Office',
                type: DynamicSearchFilterTypes.Select,
                options: {
                    selectOptions: CommonFunctions.mapMtSearchFilterItems(this.offices, 'Title')
                },
            },
            Statuses: {
                label: 'Status',
                type: DynamicSearchFilterTypes.Select,
                options: {
                    selectOptions: CommonFunctions.mapMtSearchFilterItems(pickupStatuses, 'Name')
                },
            },
            ...(this.authUserRole === UserRoles.Carrier || this.authUserRole === UserRoles.Admin ? {
                Customers: {
                    label: 'Customer',
                    type: DynamicSearchFilterTypes.Select,
                    options: { selectOptions: CommonFunctions.mapMtSearchFilterItems(this.customers) },
                }
            } : {}),
            ...(this.authUserRole === UserRoles.Carrier || this.authUserRole === UserRoles.Admin ? {
                Drivers: {
                    label: 'Driver',
                    type: DynamicSearchFilterTypes.Select,
                    options: { selectOptions: this.mapMultiMtSearchFilterItems(this.drivers, 'FirstName', 'LastName', 'Phone') },
                }
            } : {}),
            ...(this.authUserRole === UserRoles.Customer || this.authUserRole === UserRoles.Admin ? {
                Carriers: {
                    label: 'Trucking Company',
                    type: DynamicSearchFilterTypes.Select,
                    options: { selectOptions: CommonFunctions.mapMtSearchFilterItems(this.carriers, 'Name') },
                },
            } : {}),
        }];
    }

    clear(): void {
        this.pickups = null;
        this.total = null;
        this.startDate = null;
        this.endDate = null;
        this.datepickerEntityName = null;
        this.query = '';
        this.dynamicSearchFiltersConfig = [];
        this.selectedWarehouseIds = [];
        this.selectedOfficeIds = [];
        this.selectedCarrierIds = [];
        this.selectedCustomerIds = [];
        this.selectedDriverIds = [];
        this.currentPage = 1;
        this.order = 'Id';
        this.orderDirection = 'asc';
        setTimeout(() => {
            this.datepickerEntityName = 'Date Requested';
            this.buildSearchBarAndFilters();
            this.getPickups();
        });
    }

    private mapMultiMtSearchFilterItems(items: IEntity[], keyOne: string, keyTwo: string, keyThree: string): MtSearchFilterItem[] {
        return items?.map((item) => {
            return new MtSearchFilterItem(
                {
                    Id: item.Id,
                    Name: `${item[keyOne] as string} ${item[keyTwo] as string} ${item[keyThree] as string}`,
                }, false,
            );
        });
    }

    orderBy(event: PointerEvent, order: string): void {
        const target = event.target as HTMLElement;
        let selectedArrows: HTMLElement[];

        if (target.tagName === 'I') {
            const th = target.closest('th');
            selectedArrows = Array.from(th.querySelectorAll('i'));
        } else {
            selectedArrows = Array.from(target.querySelectorAll('i'));
        }

        const thead = target.closest('thead');
        const allArrows = Array.from(thead.querySelectorAll('i'));
        const allOtherArrows: HTMLElement[] = allArrows.filter(span => !selectedArrows.includes(span));
        this.clearArrowOpacity(allOtherArrows);
        this.toggleArrowOpacity(selectedArrows);
        this.order = order;
        this.getPickups();
    }

    private toggleArrowOpacity(arrows: HTMLElement[]): void {
        const upArrow = arrows[0];
        const downArrow = arrows[1];
        const upOpacity = window.getComputedStyle(upArrow).getPropertyValue('opacity');

        if (upOpacity === '1') {
            this.setOpacity(upArrow, '0.4');
            this.setOpacity(downArrow, '1');
            this.orderDirection = 'asc';
        } else {
            this.setOpacity(upArrow, '1');
            this.setOpacity(downArrow, '0.4');
            this.orderDirection = 'desc';
        }
    }

    private clearArrowOpacity(arrows: HTMLElement[]): void {
        arrows.forEach(arrow => {
            this.setOpacity(arrow, '0.4');
        });
    }

    private setOpacity(element: HTMLElement, value: string): void {
        element.style.opacity = value;
    }

    getStatusName(statusId: number): string {
        return Object.keys(PickupStatus).find(key => PickupStatus[key] === statusId);
    }

    formatDateRequested(pickup: IPickupForSearch): string {
        if (!pickup?.DateRequested)
            return '';

        if (this.isAdminUser || this.isCarrierUser) {
            const dateRequested = new Date(pickup.DateRequested)
            if (this.isMidnight(dateRequested))
                return `${this.datePipe.transform(dateRequested, 'MMM d, y')}`;

            return `${this.datePipe.transform(dateRequested, 'medium')}`;
        }

        return `${this.datePipe.transform(pickup.DateRequested)}`;
    }

    private isMidnight(date: Date): boolean {
        return date.getHours() === 0 &&
            date.getMinutes() === 0 &&
            date.getSeconds() === 0 &&
            date.getMilliseconds() === 0;
    }

    private handleError(errorResponse: HttpErrorResponse): void {
        if (typeof (errorResponse.error) === 'string') {
            this.notificationsService.error(errorResponse.error);
            return;
        }

        if (errorResponse.error?.ModelState && errorResponse.error.ModelState.length > 0) {
            this.notificationsService.error(errorResponse.error.ModelState[0] as string);
            return;
        }

        this.notificationsService.error('An unexpected error occurred.');
    }

    editPickup(pickup: IPickupForSearch): void {
        this.pickupService.isWithinAppointmentTimeframe(pickup.Id).subscribe({
            next: () => {
                void this.router.navigate(['/create-requests/order-selection', pickup.Id]);
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.handleError(errorResponse);
            }
        })
    }

    prePayPickup(pickup: IPickupForSearch): void {
        void this.router.navigate([`pickups/${pickup.Id}/pre-payment`]);
        return;
    }

    cancelPickup(pickup: IPickupForSearch): void {
        this.pickupService.isWithinAppointmentTimeframe(pickup.Id).subscribe({
            next: () => {
                const totalPrePayment = this.totalPickupPrePayment(pickup);

                let text = `Are you sure you want to cancel this pickup?`;
                if (totalPrePayment > 0)
                    text = `This pickup has been prepaid. Are you sure you want to cancel this pickup?.`;

                this.modalService.showModal({
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, Cancel Pickup',
                    showCancelButton: true,
                    cancelButtonText: `No, Go Back`,
                    text: text,
                    title: 'Cancel Pickup',
                    icon: 'warning',
                }).subscribe((result: SweetAlertResult) => {
                    if (!result.isConfirmed)
                        return;

                    this.pickupService.cancel(pickup.Id).subscribe({
                        next: () => {
                            this.modalService.showModal({
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK',
                                title: 'Pickup has been canceled.',
                                icon: 'success',
                            }).subscribe(() => {
                                location.reload();
                            });
                        }
                    });
                });
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.handleError(errorResponse);
            }
        });
    }

    confirmPickup(pickup: IPickupForSearch): void {
        this.pickupService.isWithinAppointmentTimeframe(pickup.Id).subscribe({
            next: () => {
                this.modalService.showModal({
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                    showCancelButton: true,
                    text: `Are you sure you want to confirm this pickup?`,
                    title: 'Confirm Pickup',
                    icon: 'warning',
                }).subscribe((result: SweetAlertResult) => {
                    if (!result.isConfirmed)
                        return;

                    this.pickupService.confirm(pickup.Id).subscribe({
                        next: () => {
                            this.pickupService.sendConfirmedEmails(pickup.Id).subscribe({
                                next: () => {
                                    this.modalService.showModal({
                                        confirmButtonColor: '#3085d6',
                                        confirmButtonText: 'OK',
                                        title: 'Pickup has been confirmed.',
                                        icon: 'success',
                                    }).subscribe(() => {
                                        location.reload();
                                    });
                                },
                            });
                        }
                    });
                });
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.handleError(errorResponse);
            }
        });
    }

    showEditButton(pickup: IPickupForSearch): boolean {
        // Hide the edit button if the pickup is a walk-in.
        if (pickup.WalkIn)
            return false;

        // As a Holt Admin, hide the edit button if there is no driver AND a customer created the pickup. 
        const createdByCustomer = pickup.CreatedBy?.AuthUser?.RoleId === UserRoles.Customer;
        return !(this.isAdminUser && !pickup.Driver && createdByCustomer);
    }

    showConfirmButton(pickup: IPickupForSearch): boolean {
        const allOrdersHaveOffices = pickup.Orders.every(o => o.Warehouse?.OfficeId > 0);
        return pickup.StatusId === PickupStatus.Requested && 
            this.isAdminUser && 
            allOrdersHaveOffices &&
            pickup.CarrierId > 0;
    }

    totalPalletCount(pickup: IPickupForSearch): string {
        if (!pickup.Orders)
            return '';
        const sum = pickup.Orders.reduce((sum, cur) => sum + cur.TotalPallet, 0);
        return `${sum}`;
    }

    totalOrderWeight(pickup: IPickupForSearch): string {
        if (!pickup.Orders)
            return '';
        const sum = pickup.Orders.reduce((sum, cur) => sum + cur.TotalWeight, 0);
        return `${sum.toFixed(2)} lbs`;
    }

    totalPickupPrePayment(pickup: IPickupForSearch): number {
        if (!pickup.PickupPaymentDetails)
            return 0;

        return pickup.PickupPaymentDetails
            .filter(d => d.IsSuccessful)
            .reduce((sum, cur) => sum + cur.Amount, 0);
    }

    totalRemainingBalance(pickup: IPickupForSearch): number {
        const remainaingBalance = pickup.SecurityGateFee - this.totalPickupPrePayment(pickup);
        return remainaingBalance > 0 
            ? remainaingBalance 
            : 0;
    }

    updateOrderTotalPallet(order: IOrder): void {
        this.selectedOrder = order;
        this.orderTotalPalletDialogApi.show();
    }

    updateOrderTotalWeight(order: IOrder): void {
        this.selectedOrder = order;
        this.orderTotalWeightDialogApi.show();
    }

    closeOrderTotalPalletDialog(): void {
        this.selectedOrder = null;
        this.orderTotalPalletDialogApi.close();
    }

    closeOrderTotalWeightDialog(): void {
        this.selectedOrder = null;
        this.orderTotalWeightDialogApi.close();
    }

    initShowPrePayButtons(pickups: IPickupForSearch[]): void {
        pickups.forEach(pickup => {
            const showPrePayButton = this.totalRemainingBalance(pickup) > 0 && !pickup.IsOutsidePrePaymentTimeframe && pickup.DriverId > 0;
            this.showPrePayButtons.set(pickup.Id, showPrePayButton);
        });
    }

    onWarehouseSelected(event: ISelectionChangedEvent, order: IOrder, pickup: IPickupForSearch): void {
        const selectedWarehouse = event.selection ? this.warehouses.find((o) => o.Id === event.selection.Id) : null;
        if (!selectedWarehouse)
            return;

        const otherPickupOrders = pickup.Orders.filter(o => o.Id !== order.Id);
        for (const otherOrder of otherPickupOrders) {
            if (otherOrder.WarehouseId <= 0)
                continue;

            if (otherOrder.Warehouse.OfficeId !== selectedWarehouse.OfficeId) {
                this.notificationsService.error('Orders in the same pickup must belong to the same office.');
                return;
            }
        }
        
        // Set the warehouse for the order.
        this.orderService.updateWarehouse(order.Id, selectedWarehouse.Id).subscribe({
            next: () => {
                order.Warehouse = selectedWarehouse;
                order.WarehouseId = selectedWarehouse.Id;

                // Update the security gate fee for the order's pickup.
                this.pickupService.setSecurityGateFee(pickup.Id).subscribe({
                    next: (securityGateFee: number) => {
                        pickup.SecurityGateFee = securityGateFee;
                        this.notificationsService.success('Warehouse updated successfully.');
                    },
                    error: (errorResponse: HttpErrorResponse) => {
                        this.handleError(errorResponse);
                    }
                });
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.handleError(errorResponse);
            }
        });
    }

    onCarrierSelected(event: ISelectionChangedEvent, pickup: IPickupForSearch): void {
        const selectedCarrier = event.selection ? this.carriers.find((o) => o.Id === event.selection.Id) : null;
        if (!selectedCarrier)
            return;

        this.orderService.updateCarrier(pickup.Id, selectedCarrier.Id).subscribe({
            next: () => {
                this.notificationsService.success('Carrier updated successfully.');
                pickup.Carrier = selectedCarrier;
                pickup.CarrierId = selectedCarrier.Id;
            }
        });
    }
}
