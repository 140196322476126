import { Component, inject, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { NavContentDynamicRowComponent } from '@mt-ng2/nav-module';
import { AuthService } from '@mt-ng2/auth-module';
import { OrderService } from '../orders/order.service';
import { UserRoles } from '../model/UserRoles';

@Component({
    styles: [`
        .fulfillment-reports-nav-link {
            padding: 5px 5px 5px 15px;
            display: block;
            font-size: 14px;
        }

        .fulfillment-reports-nav-link:hover {
            color: #fff;
        }

        .fulfillment-reports-nav-menu-title {
            white-space: normal;
        }
    `],
    template: `
        <a *ngIf="isAdminUser" class="nav-item fulfillment-reports-nav-link" [routerLink]="link">
            <ng-container class="ng-star-inserted">
                <div class="menu-img fa fa-fw fa-table"></div>
                <span [class.active]="isRouterLinkActive" class="menu-title fulfillment-reports-nav-menu-title">
                    Fulfillment Report
                </span>
            </ng-container>
        </a>
    `
})
export class FulfillmentReportNavComponent extends NavContentDynamicRowComponent implements OnInit {
    private router = inject(Router);
    private authService = inject(AuthService);
    private orderService = inject(OrderService);

    authUserRole: number;
    userRoles = UserRoles;
    link = '/fulfillment/report';
    routerLinkActiveOptions: IsActiveMatchOptions = {
        paths: 'exact',
        queryParams: 'exact',
        fragment: 'ignored',
        matrixParams: 'ignored',
    };

    get isRouterLinkActive(): boolean {
        return this.router.isActive(this.link, this.routerLinkActiveOptions);
    }

    get isAdminUser(): boolean {
        return this.authUserRole === this.userRoles?.Admin;
    }

    ngOnInit(): void {
        const loggedIn = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(loggedIn.AuthId).subscribe(authUserRole => {
            this.authUserRole = authUserRole;
        });
    }
}
