import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../order.service';
import { IOrder } from '../../../model/interfaces/order';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDatepickerOptions, InputTypes } from '@mt-ng2/dynamic-form';
import { PickupService } from '../../../model/shared-entities/pickups/pickup.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IModalResult, ModalService } from '@mt-ng2/modal-module';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../../../users/user.service';
import { IUser } from '../../../model/interfaces/user';
import { ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { IPickup } from '../../../model/interfaces/pickup';
import { CommonFunctions } from '../../../common/services/common-functions.service';
import { DriverTimeSlot } from '../../../model/classes/driver-time-slot';
import { PhoneFormatPipe } from '../../../common/pipes/phone-format';
import { CommonService } from '../../../common/services/common.service';
import { PickupStatus } from '../../order-basic-info/pickup-status-enum';
import { catchError, concatMap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-pickup-scheduling-driver-selection',
    templateUrl: './driver-selection.component.html',
    styleUrls: ['./driver-selection.component.css'],
})
export class PickupSchedulingDriverSelectionComponent implements OnInit {
    selectedPickup: IPickup;
    selectedOrders: IOrder[];
    drivers = [];
    driverSelectionForm: FormGroup = null;
    selectedDriverTouched = false;
    selectedDriver: IUser;
    selectedDateTime: string;
    createdPickupId: number;
    remainingBalance: number;
    isOutsidePrePaymentTimeframe: boolean;
    availableDateTimes: DriverTimeSlot[] = [];
    startDate = new Date();
    endDate = new Date();
    today = new Date();
    phoneFormatPipe: PhoneFormatPipe;
    doubleClickIsDisabled = false;
    dateRequestedField: DynamicField;
    datepickerOptions: IDatepickerOptions = { 
        minDate: { 
            year: this.today.getFullYear(), 
            month: this.today.getMonth() + 1, 
            day: this.today.getDate() 
        } 
    };

    constructor(
        private orderService: OrderService,
        private pickupService: PickupService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
        private router: Router,
        private fb: FormBuilder,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.phoneFormatPipe = new PhoneFormatPipe();
        this.orderService.selectedPickupOrders$.subscribe((selectedOrders) => {
            if (!selectedOrders || selectedOrders.length <= 0) {
                void this.router.navigate([`/create-requests/order-selection`]);
                return;
            }

            this.selectedOrders = selectedOrders;
            this.pickupService.getPickupDrivers().subscribe((drivers) => {
                drivers.forEach(driver => {
                    if (driver.CarrierId !== this.selectedOrders[0].CarrierId)
                        return;

                    const driverForDisplay = {
                        Name: `${driver.FirstName} ${driver.LastName} - ${this.phoneFormatPipe.transform(driver.Phone)}`,
                        Id: driver.Id
                    };

                    if (UserService.checkUserIsTwicVerified(driver))
                        driverForDisplay.Name = `${driverForDisplay.Name} (TWIC)`;

                    this.drivers.push(driverForDisplay);
                });
                this.drivers.sort((a, b) => a.Name.localeCompare(b.Name));
                this.pickupService.selectedPickup$.subscribe((selectedPickup) => {
                    if (selectedPickup) {
                        this.selectedPickup = selectedPickup;
                        this.selectedDriver = this.drivers?.find(f => f.Id === selectedPickup.DriverId);
                        this.selectedDateTime = this.selectedPickup.DateRequested?.toString();
                        this.startDate = new Date(this.selectedPickup.DateRequested)
                        this.endDate = new Date(this.selectedPickup.DateRequested);
                    }

                    this.dateRequestedField = new DynamicField({
                        formGroup: '',
                        label: '',
                        labelHtml: `<i class="fa fa-lg fa-fw fa-calendar"></i> <b>Select Date</b>:`,
                        name: 'dateRequested',
                        type: new DynamicFieldType({
                            fieldType: DynamicFieldTypes.Input,
                            inputType: InputTypes.Datepicker,
                            datepickerOptions: this.datepickerOptions
                        }),
                        validators: { required: true },
                        value: this.selectedPickup?.DateRequested ?? this.today,
                    });

                    this.driverSelectionForm = this.fb.group({
                        deliveryZipCode: [this.selectedPickup?.DeliveryZipCode ?? '', [Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]],
                        dateRequested: new FormControl(this.dateRequestedField.value, [Validators.required])
                    });
                });
            });
        });
    }

    onDriverSelected(event: ISelectionChangedEvent): void {
        this.selectedDriverTouched = true;
        this.selectedDriver = this.drivers.find(f => f.Id === event['selection']?.Id);
        this.getSearchTimes();
    }

    startDateChange(event: Date): void {
        this.startDate = event;
        this.endDate = event;
        this.getSearchTimes();
    }

    onDriverSelectionFormSubmit(): void {
        if (this.selectedPickup?.Id > 0) {
            this.updateExistingPickup();
        } else {
            this.createNewPickup();
        }
    }

    onDateTimeSelected(dateTime: string) {
        this.selectedDateTime = dateTime;
    }

    submitButtonShouldBeDisabled(): boolean {
        if (!this.selectedDateTime || this.driverSelectionForm.controls.deliveryZipCode.invalid || this.doubleClickIsDisabled)
            return true;

        return !(this.availableDateTimes.find(time => time.TimeSlot.toString() === this.selectedDateTime));
    }

    cancelPickup(pickup: IPickup): void {
        this.commonService.cancelPickup(pickup);
    }

    private createNewPickup(): void {
        const params = {
            driverId: this.selectedDriver?.Id,
            dateRequested: CommonFunctions.getUTCEquivalent(this.selectedDateTime),
            dateConfirmed: null,
            deliveryZipCode: this.driverSelectionForm.controls.deliveryZipCode.value as string,
            orders: this.selectedOrders,
            statusId: PickupStatus.Requested,
            carrierId: this.selectedOrders[0].CarrierId,
        }

        this.pickupService.createScheduledPickup(params).pipe(
            concatMap((pickup: IPickup) => {
                this.createdPickupId = pickup.Id;
                return this.pickupService.sendUpdatedEmails(pickup.Id);
            }),
            concatMap(() => this.pickupService.getRemainingBalance(this.createdPickupId)),
            concatMap((remainingBalance) => {
                this.remainingBalance = remainingBalance;
                return this.pickupService.isOutsidePrePaymentTimeframe(this.createdPickupId);
            }),
        ).subscribe({
            next: (isOutsidePrePaymentTimeframe) => {
                this.isOutsidePrePaymentTimeframe = isOutsidePrePaymentTimeframe;
                this.showSuccessModal(this.createdPickupId);
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.handleErrorResponse(errorResponse);
            }
        });
    }

    private updateExistingPickup(): void {
        this.selectedPickup.Orders = this.selectedOrders;
        this.selectedPickup.DriverId = this.selectedDriver?.Id;
        this.selectedPickup.DateRequested = CommonFunctions.getUTCEquivalent(this.selectedDateTime);
        this.selectedPickup.DeliveryZipCode = this.driverSelectionForm.controls.deliveryZipCode.value as string;

        this.pickupService.updateWithFks(this.selectedPickup).pipe(
            concatMap(() => {
                return this.pickupService.sendUpdatedEmails(this.selectedPickup.Id);
            }),
            concatMap(() => this.pickupService.getRemainingBalance(this.selectedPickup.Id)),
            concatMap((remainingBalance) => {
                this.remainingBalance = remainingBalance;
                return this.pickupService.isOutsidePrePaymentTimeframe(this.selectedPickup.Id);
            }),
        ).subscribe({
            next: (isOutsidePrePaymentTimeframe) => {
                this.isOutsidePrePaymentTimeframe = isOutsidePrePaymentTimeframe;
                this.showSuccessModal(this.selectedPickup.Id);
            },
            error: (errorResponse: HttpErrorResponse) => {
                this.handleErrorResponse(errorResponse);
            }
        });
    }

    private getSearchTimes() {
        this.pickupService.getDriverSearchTimes(
            this.selectedOrders.map(o => o.Id),
            CommonFunctions.setToMidnight(this.startDate),
            CommonFunctions.setToMidnight(this.endDate),
            this.selectedOrders[0].CustomerId,
            this.selectedOrders[0].Warehouse?.OfficeId,
            this.selectedDriver?.Id ?? 0
        )
        .subscribe((availableDateTimes) => {
            this.availableDateTimes = availableDateTimes;
        });
    }

    private showSuccessModal(pickupId: number): void {
        if (this.remainingBalance > 0 && !this.isOutsidePrePaymentTimeframe) {
            const html = `
                <p>Our team is reviewing your request and will confirm your pickup date and time ASAP.</p>
                <p>There is a balance of $${this.remainingBalance.toFixed(2)} for driver ${this.selectedDriver['Name'] as string} to pay.</p>
                <p>Do you want to pay in advance for the pickup?</p>
            `;

            this.modalService
                .showModal({
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    html: html,
                    title: 'Got It!',
                    icon: 'success',
                    showConfirmButton: true,
                    showCancelButton: true,
                })
                .subscribe((result: IModalResult) => {
                    if (result.isConfirmed) {
                        void this.router.navigate([`pickups/${pickupId}/pre-payment`]);
                        return;
                    }

                    void this.router.navigate(['create-requests/manage-pickups']);
                });
            return;
        }

        this.modalService
            .showModal({
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                text: `Our team is reviewing your request and will confirm your pickup date and time ASAP.`,
                title: 'Got It!',
                icon: 'success',
            })
            .subscribe(() => {
                void this.router.navigate(['create-requests/manage-pickups']);
            });
    }

    private handleErrorResponse(errorResponse: HttpErrorResponse): void {
        if (typeof(errorResponse.error) === 'string') {
            this.notificationsService.error(errorResponse.error);
            return;
        }
        this.notificationsService.error(errorResponse.error['ModelState'][0] as string);
    }
}
