import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IRegistrationRequest } from '../interfaces/registration-request';
import { ICarrier } from '../interfaces/carrier';
import { ICustomer } from '../interfaces/customer';
import { IUser } from '../interfaces/user';
import { IRegistrationRequestStatus } from '../interfaces/registration-request-status';

export interface IRegistrationRequestDynamicControlsParameters {
    formGroup?: string;
    carriers?: ICarrier[];
    customers?: ICustomer[];
    statuses?: IRegistrationRequestStatus[];
    users?: IUser[];
    modifiedBies?: IUser[];
}

export class RegistrationRequestDynamicControls {

    formGroup: string;
    carriers?: ICarrier[];
    customers?: ICustomer[];
    statuses?: IRegistrationRequestStatus[];
    users?: IUser[];
    modifiedBies?: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private registrationrequest?: IRegistrationRequest, additionalParameters?: IRegistrationRequestDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'RegistrationRequest';
        this.carriers = additionalParameters && additionalParameters.carriers || undefined;
        this.customers = additionalParameters && additionalParameters.customers || undefined;
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;
        this.users = additionalParameters && additionalParameters.users || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            CarrierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier',
                name: 'CarrierId',
                options: this.carriers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.CarrierId || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.CustomerId || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.DateModified || null,
            }),
            EmailAddress: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Address',
                name: 'EmailAddress',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('EmailAddress') && this.registrationrequest.EmailAddress != null ? this.registrationrequest.EmailAddress.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('FirstName') && this.registrationrequest.FirstName != null ? this.registrationrequest.FirstName.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('LastName') && this.registrationrequest.LastName != null ? this.registrationrequest.LastName.toString() : '',
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.ModifiedById || null,
            }),
            NotificationWhenDriverChecksIn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification When Driver Checks In',
                name: 'NotificationWhenDriverChecksIn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('NotificationWhenDriverChecksIn') && this.registrationrequest.NotificationWhenDriverChecksIn != null ? this.registrationrequest.NotificationWhenDriverChecksIn : false,
            }),
            NotificationWhenDriverIsFulfilled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification When Driver Is Fulfilled',
                name: 'NotificationWhenDriverIsFulfilled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('NotificationWhenDriverIsFulfilled') && this.registrationrequest.NotificationWhenDriverIsFulfilled != null ? this.registrationrequest.NotificationWhenDriverIsFulfilled : false,
            }),
            NotificationWhenDriverIsIssuedGatePass: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification When Driver Is Issued Gate Pass',
                name: 'NotificationWhenDriverIsIssuedGatePass',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('NotificationWhenDriverIsIssuedGatePass') && this.registrationrequest.NotificationWhenDriverIsIssuedGatePass != null ? this.registrationrequest.NotificationWhenDriverIsIssuedGatePass : false,
            }),
            NotificationWhenDriverIsNotified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification When Driver Is Notified',
                name: 'NotificationWhenDriverIsNotified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('NotificationWhenDriverIsNotified') && this.registrationrequest.NotificationWhenDriverIsNotified != null ? this.registrationrequest.NotificationWhenDriverIsNotified : false,
            }),
            PhoneNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Phone Number',
                name: 'PhoneNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('PhoneNumber') && this.registrationrequest.PhoneNumber != null ? this.registrationrequest.PhoneNumber.toString() : '',
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.StatusId || 1,
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.registrationrequest && this.registrationrequest.UserId || null,
            }),
        };

        this.View = {
            CarrierId: new DynamicLabel({
                label: 'Carrier',
                value: getMetaItemValue(this.carriers as unknown as IMetaItem[], this.registrationrequest && this.registrationrequest.hasOwnProperty('CarrierId') ? this.registrationrequest.CarrierId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.registrationrequest && this.registrationrequest.hasOwnProperty('CustomerId') ? this.registrationrequest.CustomerId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.registrationrequest && this.registrationrequest.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.registrationrequest && this.registrationrequest.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            EmailAddress: new DynamicLabel({
                label: 'Email Address',
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('EmailAddress') && this.registrationrequest.EmailAddress != null ? this.registrationrequest.EmailAddress.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('FirstName') && this.registrationrequest.FirstName != null ? this.registrationrequest.FirstName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('LastName') && this.registrationrequest.LastName != null ? this.registrationrequest.LastName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.registrationrequest && this.registrationrequest.hasOwnProperty('ModifiedById') ? this.registrationrequest.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            NotificationWhenDriverChecksIn: new DynamicLabel({
                label: 'Notification When Driver Checks In',
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('NotificationWhenDriverChecksIn') && this.registrationrequest.NotificationWhenDriverChecksIn != null ? this.registrationrequest.NotificationWhenDriverChecksIn : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NotificationWhenDriverIsFulfilled: new DynamicLabel({
                label: 'Notification When Driver Is Fulfilled',
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('NotificationWhenDriverIsFulfilled') && this.registrationrequest.NotificationWhenDriverIsFulfilled != null ? this.registrationrequest.NotificationWhenDriverIsFulfilled : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NotificationWhenDriverIsIssuedGatePass: new DynamicLabel({
                label: 'Notification When Driver Is Issued Gate Pass',
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('NotificationWhenDriverIsIssuedGatePass') && this.registrationrequest.NotificationWhenDriverIsIssuedGatePass != null ? this.registrationrequest.NotificationWhenDriverIsIssuedGatePass : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NotificationWhenDriverIsNotified: new DynamicLabel({
                label: 'Notification When Driver Is Notified',
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('NotificationWhenDriverIsNotified') && this.registrationrequest.NotificationWhenDriverIsNotified != null ? this.registrationrequest.NotificationWhenDriverIsNotified : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhoneNumber: new DynamicLabel({
                label: 'Phone Number',
                value: this.registrationrequest && this.registrationrequest.hasOwnProperty('PhoneNumber') && this.registrationrequest.PhoneNumber != null ? this.registrationrequest.PhoneNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            StatusId: new DynamicLabel({
                label: 'Status',
                value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.registrationrequest && this.registrationrequest.hasOwnProperty('StatusId') ? this.registrationrequest.StatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            UserId: new DynamicLabel({
                label: 'User',
                value: getMetaItemValue(this.users as unknown as IMetaItem[], this.registrationrequest && this.registrationrequest.hasOwnProperty('UserId') ? this.registrationrequest.UserId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
